import { FC, ReactNode } from 'react';
import { NonNullableDateRange } from 'types';
import { CpaStatisticDetailed } from '../CpaStatisticDetailed';
import { RevShareStatisticDetailed } from '../RevShareStatisticDetailed';
import { StatisticType } from '../../../../const';

interface StatisticDetailedByTypeProps {
  type: StatisticType;
  referralLinkMediaItemId: string;
  nonNullableDateRange: NonNullableDateRange;
}

export const StatisticDetailedByType: FC<StatisticDetailedByTypeProps> = ({
  referralLinkMediaItemId,
  type,
  nonNullableDateRange,
}) => {
  const mapTypeToNode: Record<StatisticType, ReactNode> = {
    [StatisticType.Cpa]: (
      <CpaStatisticDetailed
        cpaMediaItemId={referralLinkMediaItemId}
        nonNullableDateRange={nonNullableDateRange}
      />
    ),
    [StatisticType.RevShare]: (
      <RevShareStatisticDetailed
        revShareMediaItemId={referralLinkMediaItemId}
        nonNullableDateRange={nonNullableDateRange}
      />
    ),
  };

  return mapTypeToNode[type];
};
