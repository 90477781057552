import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { PromoCodeMediaItemData } from '../../../MediaDataBlock/components/PromoCodeMediaTable/queries/fragments/generated/PromoCodeMediaItemData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetPromoCodeMediaItemVariables = SchemaTypes.Exact<{
  promoCodeMediaItemId: SchemaTypes.Scalars['ID']['input'];
  userId: SchemaTypes.Scalars['ID']['input'];
}>;

export type GetPromoCodeMediaItem = {
  __typename: 'Query';
  user:
    | {
        __typename: 'Company';
        id: string;
        promoCodeMediaItem: {
          __typename: 'PromoCodeMediaItem';
          createdAt: string;
          id: string;
          referralLink: string;
          tariffName: string;
          token: string;
          type: SchemaTypes.PromoCodeMediaItemType;
          bonus?: {
            __typename: 'PromoCodeBonus';
            cashout?: number | null;
            wager?: number | null;
            amount: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            minBalance?: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            } | null;
          } | null;
          freespin?: {
            __typename: 'PromoCodeFreespin';
            count: number;
            currency: CommonType.Currency;
            wager?: number | null;
            minBalance?: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            } | null;
          } | null;
          owner:
            | { __typename: 'Company'; id: string; name: string }
            | { __typename: 'CompanyPartner'; id: string; username: string }
            | { __typename: 'Partner'; id: string; username: string };
        };
      }
    | {
        __typename: 'Partner';
        id: string;
        promoCodeMediaItem: {
          __typename: 'PromoCodeMediaItem';
          createdAt: string;
          id: string;
          referralLink: string;
          tariffName: string;
          token: string;
          type: SchemaTypes.PromoCodeMediaItemType;
          bonus?: {
            __typename: 'PromoCodeBonus';
            cashout?: number | null;
            wager?: number | null;
            amount: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            minBalance?: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            } | null;
          } | null;
          freespin?: {
            __typename: 'PromoCodeFreespin';
            count: number;
            currency: CommonType.Currency;
            wager?: number | null;
            minBalance?: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            } | null;
          } | null;
          owner:
            | { __typename: 'Company'; id: string; name: string }
            | { __typename: 'CompanyPartner'; id: string; username: string }
            | { __typename: 'Partner'; id: string; username: string };
        };
      };
};

export const GetPromoCodeMediaItemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPromoCodeMediaItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'promoCodeMediaItemId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Partner' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'promoCodeMediaItem' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'promoCodeMediaItemId',
                              },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'PromoCodeMediaItem',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'PromoCodeMediaItemData',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Company' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'promoCodeMediaItem' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'promoCodeMediaItemId',
                              },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'PromoCodeMediaItem',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'PromoCodeMediaItemData',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PromoCodeMediaItemData.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetPromoCodeMediaItem__
 *
 * To run a query within a React component, call `useGetPromoCodeMediaItem` and pass it any options that fit your needs.
 * When your component renders, `useGetPromoCodeMediaItem` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromoCodeMediaItem({
 *   variables: {
 *      promoCodeMediaItemId: // value for 'promoCodeMediaItemId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetPromoCodeMediaItem(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetPromoCodeMediaItem,
    GetPromoCodeMediaItemVariables
  > &
    (
      | { variables: GetPromoCodeMediaItemVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetPromoCodeMediaItem,
    GetPromoCodeMediaItemVariables
  >(GetPromoCodeMediaItemDocument, options);
}
export function useGetPromoCodeMediaItemLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPromoCodeMediaItem,
    GetPromoCodeMediaItemVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetPromoCodeMediaItem,
    GetPromoCodeMediaItemVariables
  >(GetPromoCodeMediaItemDocument, options);
}
export function useGetPromoCodeMediaItemSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetPromoCodeMediaItem,
    GetPromoCodeMediaItemVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetPromoCodeMediaItem,
    GetPromoCodeMediaItemVariables
  >(GetPromoCodeMediaItemDocument, options);
}
export type GetPromoCodeMediaItemHookResult = ReturnType<
  typeof useGetPromoCodeMediaItem
>;
export type GetPromoCodeMediaItemLazyQueryHookResult = ReturnType<
  typeof useGetPromoCodeMediaItemLazyQuery
>;
export type GetPromoCodeMediaItemSuspenseQueryHookResult = ReturnType<
  typeof useGetPromoCodeMediaItemSuspenseQuery
>;
export type GetPromoCodeMediaItemQueryResult = Apollo.QueryResult<
  GetPromoCodeMediaItem,
  GetPromoCodeMediaItemVariables
>;
