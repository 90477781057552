import { FormikErrors } from 'formik';
import { checkEmailValidity } from 'utils/form/checkEmailValidity';
import { getRequiredErrors } from 'utils/form/getRequiredFormikErrors';
import { getPasswordError } from 'utils/form/getPasswordError';
import { getMinLengthErrors } from 'utils/form/getMinLengthErrors';
import { t } from 'i18n';
import type { RegistrationValues } from './types';
import { FieldName } from './const';

export const validatePartnerRegistration = (
  values: RegistrationValues
): FormikErrors<RegistrationValues> => {
  const errors: FormikErrors<RegistrationValues> = {};

  if (!checkEmailValidity(values.email)) {
    errors.email = t(
      'auth:signup.partner.input.registration.validation.invalidEmail'
    );
  }

  const passwordError = getPasswordError(values.createPassword);

  if (passwordError) {
    errors.createPassword = passwordError;
  }

  if (values.createPassword !== values.confirmPassword) {
    errors.confirmPassword = t(
      'auth:signup.partner.input.registration.validation.invalidConfirmPassword'
    );
  }

  const requiredErrors = getRequiredErrors(values, {
    [FieldName.CreatePassword]: true,
    [FieldName.ConfirmPassword]: true,
    [FieldName.ContactType]: true,
    [FieldName.ContactText]: true,
    [FieldName.TrafficType]: false,
    [FieldName.Email]: true,
    [FieldName.CurrencySelect]: true,
    [FieldName.Username]: true,
  });

  const minLengthErrors = getMinLengthErrors(values, {
    [FieldName.Username]: 6,
  });

  return {
    ...errors,
    ...requiredErrors,
    ...minLengthErrors,
  };
};
