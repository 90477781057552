import { DateFormat, formatDate } from '../../../utils/formatDate';

interface Params {
  startDate: Date | null;
  endDate: Date | null;
}
export const getDateTitle = ({
  startDate,
  endDate,
}: Params): [string | null, string | null] => {
  if (!startDate) {
    return [null, null];
  }

  return [
    startDate ? formatDate(startDate, DateFormat.DefaultDate) : null,
    endDate ? formatDate(endDate, DateFormat.DefaultDate) : null,
  ];
};
