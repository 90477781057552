import { FC, PropsWithChildren } from 'react';
import styles from './PromoCodeMediaSection.module.scss';

interface PromoCodeMediaSectionProps {
  title: string;
}

export const PromoCodeMediaSection: FC<
  PropsWithChildren<PromoCodeMediaSectionProps>
> = ({ title, children }) => (
  <div className={styles.container}>
    <h3 className={styles.headline}>{title}</h3>
    {children}
  </div>
);
