import { Column } from '@tanstack/react-table';

export const getGridTemplateColumns = <TData>(columns: Array<Column<TData>>) =>
  columns.reduce((currentColumnTemplate, column) => {
    const { size, minSize } = column.columnDef;

    if (minSize) {
      return `${currentColumnTemplate}minmax(${minSize}px, 1fr) `;
    }

    return `${currentColumnTemplate}${size}px `;
  }, '');
