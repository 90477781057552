import { FC } from 'react';
import { TopBar } from 'components/TopBar';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MediaIcon } from 'assets/icons/colors/Image.svg';
import { useOpenReferralLinkModal } from './hooks/useOpenReferralLinkModal';
import { MediaDataBlock } from './components/MediaDataBlock';

export const Media: FC = () => {
  const { t } = useTranslation('media');
  const { openCreateModal } = useOpenReferralLinkModal();

  return (
    <>
      <TopBar
        title={t('placeholder.header.header')}
        icon={MediaIcon}
        action={{
          buttonText: t('placeholder.action.button.addMedia'),
          onClick: openCreateModal,
        }}
      />
      <MediaDataBlock />
    </>
  );
};
