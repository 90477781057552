import { t } from 'i18n';
import { Login } from 'pages/Login';
import { Registration } from 'pages/Registration';
import { RegistrationPartner } from 'pages/Registration/pages/RegistrationPartner';
import { RegistrationCompany } from 'pages/Registration/pages/RegistrationCompany';
import { Main } from 'pages/Main';
import { Application } from 'pages/Application';
import { Statistic, StatisticOutletContext } from 'pages/Statistic';
import { StatisticDetailed } from 'pages/Statistic/pages/StatisticDetailed';
import { StatisticType } from 'pages/Statistic/const';
import { Tariffs } from 'pages/Tariffs';
import { StatisticGeneral } from 'pages/Statistic/pages/StatisticGeneral';
import { TwoFactor } from 'pages/TwoFactor';
import { Media } from 'pages/Media';
import { Support } from 'pages/Support';
import { NotFound } from 'pages/NotFound';
import { TwoFactorConnected } from 'pages/TwoFactorConnected';
import { Profile } from 'pages/Profile';
import { Reapplication } from 'pages/Reapplication';
import { PasswordReset } from 'pages/PasswordReset';
import { PasswordChange } from 'pages/PasswordChange';
import { CompanyPartners } from 'pages/CompanyPartners';
import { Payments } from 'pages/Payments';
import { AppRoute } from 'const';
import { Outlet, RouteObject } from 'react-router';
import { AuthLayout } from '../layouts/AuthLayout';
import { ErrorLayout } from '../layouts/ErrorLayout';
import { MainLayout } from '../layouts/MainLayout';
import { PrivateRouteWrapper } from './components/PrivateRouteWrapper';
import { RedirectByUserStatusWrapper } from './components/RedirectByUserStatusWrapper';
import { ActiveUserWrapper } from './components/ActiveUserWrapper';
import { NotActiveUserWrapper } from './components/NotActiveUserWrapper';
import { LanguageWrapper } from './components/LanguageWrapper';
import { NavigateToNormalizedPathname } from './components/NavigateToNormalizedPathname';

const publicRoutes: Array<RouteObject> = [
  {
    element: <AuthLayout />,
    children: [
      {
        element: <RedirectByUserStatusWrapper />,
        children: [
          {
            path: AppRoute.Lang.Login,
            element: <Login />,
          },
          {
            path: AppRoute.Lang.Registration,
            element: <Registration />,
          },
          {
            path: AppRoute.Lang.RegistrationPartner,
            element: <RegistrationPartner />,
          },
          {
            path: AppRoute.Lang.RegistrationCompany,
            element: <RegistrationCompany />,
          },
          {
            path: AppRoute.Lang.PasswordReset,
            element: <PasswordReset />,
          },
          {
            path: AppRoute.Lang.PasswordChange,
            element: <PasswordChange />,
          },
        ],
      },
      {
        path: AppRoute.Lang.TwoFactor,
        element: <TwoFactor />,
      },
      {
        path: AppRoute.Lang.TwoFactorConnected,
        element: <TwoFactorConnected />,
      },
      {
        path: AppRoute.Lang.Support,
        element: <Support />,
      },
    ],
  },
  {
    element: <ErrorLayout />,
    children: [
      {
        path: AppRoute.Lang.AllPaths,
        element: <NotFound />,
      },
    ],
  },
];

const privateRoutes: Array<RouteObject> = [
  {
    element: <PrivateRouteWrapper />,
    children: [
      {
        element: <ActiveUserWrapper />,
        children: [
          {
            element: <MainLayout />,
            children: [
              {
                path: AppRoute.Lang.Main,
                element: <Main />,
              },
              {
                path: AppRoute.Lang.Statistic.root,
                children: [
                  {
                    index: true,
                    element: <Statistic />,
                  },
                  {
                    path: AppRoute.Lang.Statistic.Cpa.root,
                    element: (
                      <Outlet
                        context={
                          {
                            statisticType: StatisticType.Cpa,
                          } satisfies StatisticOutletContext
                        }
                      />
                    ),
                    handle: {
                      crumb: () =>
                        t('statistic:partner.action.breadcrumbs.statisticsCpa'),
                    },
                    children: [
                      {
                        index: true,
                        element: <StatisticGeneral />,
                      },
                      {
                        path: AppRoute.Lang.Statistic.Cpa.MediaItem,
                        element: <StatisticDetailed />,
                        loader: ({ params }) => {
                          if (!params.referralLinkMediaItemId) {
                            throw new Error(
                              'referralLinkMediaItemId is missing'
                            );
                          }

                          return params.referralLinkMediaItemId;
                        },
                        handle: {
                          crumb: (referralLinkMediaItemId: string) =>
                            referralLinkMediaItemId,
                        },
                      },
                    ],
                  },
                  {
                    path: AppRoute.Lang.Statistic.RevShare.root,
                    element: (
                      <Outlet
                        context={
                          {
                            statisticType: StatisticType.RevShare,
                          } satisfies StatisticOutletContext
                        }
                      />
                    ),
                    handle: {
                      crumb: () =>
                        t(
                          'statistic:partner.action.breadcrumbs.statisticsRevShare'
                        ),
                    },
                    children: [
                      {
                        index: true,
                        element: <StatisticGeneral />,
                      },
                      {
                        path: AppRoute.Lang.Statistic.RevShare.MediaItem,
                        element: <StatisticDetailed />,
                        loader: ({ params }) => {
                          if (!params.referralLinkMediaItemId) {
                            throw new Error(
                              'referralLinkMediaItemId is missing'
                            );
                          }

                          return params.referralLinkMediaItemId;
                        },
                        handle: {
                          crumb: (referralLinkMediaItemId: string) =>
                            referralLinkMediaItemId,
                        },
                      },
                    ],
                  },
                ],
              },
              {
                path: AppRoute.Lang.Tariffs,
                element: <Tariffs />,
              },
              {
                path: AppRoute.Lang.Media,
                element: <Media />,
              },
              {
                path: AppRoute.Lang.Profile,
                element: <Profile />,
              },
              {
                path: AppRoute.Lang.CompanyPartners,
                element: <CompanyPartners />,
              },
              {
                path: AppRoute.Lang.Payments,
                element: <Payments />,
              },
            ],
          },
        ],
      },
      {
        element: <NotActiveUserWrapper />,
        children: [
          {
            element: <AuthLayout />,
            children: [
              {
                path: AppRoute.Lang.Application,
                element: <Application />,
              },
              {
                path: AppRoute.Lang.Reapplication,
                element: <Reapplication />,
              },
            ],
          },
        ],
      },
    ],
  },
];

export const routes: Array<RouteObject> = [
  {
    path: AppRoute.Main,
    element: <LanguageWrapper />,
    children: [
      {
        path: AppRoute.Lang.root,
        children: [...publicRoutes, ...privateRoutes],
      },
    ],
  },
  {
    path: AppRoute.AllPaths,
    element: <NavigateToNormalizedPathname />,
  },
];
