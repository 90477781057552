import { FC, ReactNode } from 'react';
import styles from './SearchBlock.module.scss';

interface SearchBlockProps {
  searchComponent?: ReactNode;
}

export const SearchBlock: FC<SearchBlockProps> = ({ searchComponent }) => {
  if (!searchComponent) {
    return null;
  }

  return <div className={styles.container}>{searchComponent}</div>;
};
