import { Language } from 'types/generated/gql';

export const SCROLL_LOCKED_CLASS_NAME = 'scrollLocked';
export const SPEC_HEADER_LARGE = 'specHeaderLarge';

export const AFFILIATE_EMAIL = 'affiliate@vavada.net';
export const nbsp = '\u00A0';

export const defaultLanguageCode = Language.En;

export const PRIORITY_CURRENCY_CODES = ['USD', 'EUR', 'RUB'];
