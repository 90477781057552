import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useModalContext, openModal } from 'ui/Modal';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { PromoCodeDetailsModal } from '../../../../PromoCodeDetailsModal';

export const usePromoCodeDetailsModal = () => {
  const { t } = useTranslation('media');
  const { dispatch } = useModalContext();
  const translationUserType = useTranslationUserType();

  const openDetailsModal = useCallback(
    (promoCodeMediaItemId: string) => {
      dispatch(
        openModal({
          title: t(
            `${translationUserType}.content.drawer.details.header.title`
          ),
          content: (
            <PromoCodeDetailsModal
              promoCodeMediaItemId={promoCodeMediaItemId}
            />
          ),
        })
      );
    },
    [dispatch, t, translationUserType]
  );

  return { openDetailsModal };
};
