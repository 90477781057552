import * as SchemaTypes from '../../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { RevShareReferralLinkStatisticConnectionData } from './RevShareReferralLinkStatisticConnectionData';
import { RevShareReferralLinkStatisticItemData } from './RevShareReferralLinkStatisticItemData';
import { RevShareReferralLinkStatisticInfoData } from './RevShareReferralLinkStatisticInfoData';
import { MoneyAmountData } from '../../../../../../../../../queries/fragments/generated/MoneyAmountData';
import { PageInfoData } from '../../../../../../../../../queries/fragments/generated/PageInfoData';
export type RevShareReferralLinkStatisticData = {
  __typename: 'ReferralLinkMediaItem';
  id: string;
  link: string;
  revShareStatistic: {
    __typename: 'RevShareReferralLinkStatistic';
    statisticItems: {
      __typename: 'RevShareReferralLinkStatisticConnection';
      edges: Array<{
        __typename: 'RevShareReferralLinkStatisticEdge';
        cursor: string;
        node: {
          __typename: 'RevShareReferralLinkStatisticItem';
          id: string;
          playerName?: string | null;
          statisticInfo: {
            __typename: 'RevShareReferralLinkStatisticInfo';
            numberOfRedeposits: number;
            wasFD: boolean;
            adminFee: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            averageDeposit: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            depositsAll: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            depositsFee: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            redepositsAll: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            withdrawalsAll: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            withdrawalsFee: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
          };
        };
      }>;
      pageInfo: {
        __typename: 'PageInfo';
        endCursor?: string | null;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
        startCursor: string;
      };
    };
  };
};

export const RevShareReferralLinkStatisticData = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RevShareReferralLinkStatisticData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReferralLinkMediaItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'link' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'revShareStatistic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'statisticItems' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'after' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'after' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'end' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'end' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'first' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'start' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'start' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'RevShareReferralLinkStatisticConnectionData',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
