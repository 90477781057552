import { FC } from 'react';
import { Falsy } from 'types';
import { useTranslation } from 'react-i18next';
import { KeyValueGrid, KeyValueGridRow } from 'ui/KeyValueGrid';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { PromoCodeBonus } from 'types/generated/gql';
import { getMoneyAmount } from 'utils/currency/getMoneyAmount';
import { getValueWithFallback } from 'utils/getValueWithFallback';
import { PromoCodeMediaSection } from '../PromoCodeMediaSection';

interface PromoCodeMediBonusProps {
  promoCodeMediaItemBonus?: PromoCodeBonus | null;
}

export const PromoCodeMediaBonus: FC<PromoCodeMediBonusProps> = ({
  promoCodeMediaItemBonus,
}) => {
  const { t } = useTranslation('media');
  const translationUserType = useTranslationUserType();

  const getKeyValueGridRows = (
    bonus: PromoCodeBonus
  ): Array<KeyValueGridRow | Falsy> => [
    {
      title: t(
        `${translationUserType}.content.drawer.details.promoCode.bonuses.label.sum`
      ),
      value: getMoneyAmount(bonus.amount),
    },
    {
      title: t(
        `${translationUserType}.content.drawer.details.promoCode.bonuses.label.wager`
      ),
      value: getValueWithFallback({ value: bonus.wager }),
    },
    {
      title: t(
        `${translationUserType}.content.drawer.details.promoCode.bonuses.label.cashOut`
      ),
      value: getValueWithFallback({ value: bonus.cashout }),
    },
    {
      title: t(
        `${translationUserType}.content.drawer.details.promoCode.bonuses.label.minimumBalance`
      ),
      value: bonus.minBalance ? getMoneyAmount(bonus.minBalance) : '—',
    },
  ];

  if (!promoCodeMediaItemBonus) return null;

  return (
    <PromoCodeMediaSection
      title={t(
        `${translationUserType}.content.drawer.details.promoCode.bonuses.label.headline`
      )}
    >
      <KeyValueGrid
        data={getKeyValueGridRows(promoCodeMediaItemBonus)}
        shouldStackOnMd
      />
    </PromoCodeMediaSection>
  );
};
