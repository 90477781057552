import { FC } from 'react';
import { Falsy } from 'types';
import { useTranslation } from 'react-i18next';
import { DateFormat, formatDate } from 'utils/formatDate';
import { KeyValueGrid, KeyValueGridRow } from 'ui/KeyValueGrid';
import { CopyLink } from 'ui/CopyLink';
import { CopyText } from 'ui/CopyText';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { mapPromoCodeTypeToText } from 'pages/Media/components/MediaDataBlock/components/PromoCodeMediaTable/helpers';
import { getMediaItemOwnerToShow } from '../../../EditReferralLinkMediaModal/components/ReferralLinkMediaDetails/helpers';
import { PromoCodeMediaItemData } from '../../../MediaDataBlock/components/PromoCodeMediaTable/queries/fragments/generated/PromoCodeMediaItemData';
import { PromoCodeMediaSection } from '../PromoCodeMediaSection';

interface PromoCodeMediaDetailsProps {
  promoCodeMediaItem: PromoCodeMediaItemData;
}

export const PromoCodeMediaDetails: FC<PromoCodeMediaDetailsProps> = ({
  promoCodeMediaItem,
}) => {
  const { createdAt, type, tariffName, token, referralLink } =
    promoCodeMediaItem;
  const { t } = useTranslation(['common', 'media']);
  const translationUserType = useTranslationUserType();

  const promoCodeMediaItemOwner = getMediaItemOwnerToShow(
    promoCodeMediaItem.owner
  );

  const keyValueGridRows: Array<KeyValueGridRow | Falsy> = [
    {
      title: t(
        `media:${translationUserType}.content.drawer.details.promoCode.about.label.promoType`
      ),
      value: mapPromoCodeTypeToText(type, translationUserType),
    },
    {
      title: t(
        `media:${translationUserType}.content.drawer.details.promoCode.about.label.tariff`
      ),
      value: tariffName,
    },
    promoCodeMediaItemOwner && {
      title: t(
        `media:company.content.drawer.details.promoCode.about.label.owner`
      ),
      value: promoCodeMediaItemOwner,
    },
    {
      title: t(
        `media:${translationUserType}.content.drawer.details.promoCode.about.label.token`
      ),
      value: (
        <CopyText tooltipText={t('tokenCopied')} justifyBetween truncateText>
          {token}
        </CopyText>
      ),
    },
    {
      title: t(
        `media:${translationUserType}.content.drawer.details.promoCode.about.label.link`
      ),
      value: (
        <CopyLink
          target="_blank"
          to={referralLink}
          tooltipText={t('linkCopied')}
        >
          {referralLink}
        </CopyLink>
      ),
    },
    {
      title: t(
        `media:${translationUserType}.content.drawer.details.promoCode.about.label.createdDate`
      ),
      value: formatDate(new Date(createdAt), DateFormat.FullDateWithoutHours),
    },
  ];

  return (
    <PromoCodeMediaSection
      title={t(
        `media:${translationUserType}.content.drawer.details.promoCode.about.label.headline`
      )}
    >
      <KeyValueGrid data={keyValueGridRows} shouldStackOnMd />
    </PromoCodeMediaSection>
  );
};
