import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Select, SelectOption } from 'ui/formItems';
import { Currency } from 'types';
import { FieldName } from '../../const';
import { NextRewardCurrencyFormValues } from '../../types';

interface NextRewardCurrencySelectProps {
  onDirtyChange: (dirty: boolean) => void;
  options: Array<SelectOption>;
  disabled?: boolean;
  onChange?: (value: Currency) => void;
}

export const NextRewardCurrencySelect = ({
  onDirtyChange,
  options,
  disabled,
  onChange,
}: NextRewardCurrencySelectProps) => {
  const { t } = useTranslation('payments');
  const {
    dirty,
    values: { currency },
  } = useFormikContext<NextRewardCurrencyFormValues>();

  useEffect(() => {
    onDirtyChange(dirty);
  }, [dirty, onDirtyChange]);

  useEffect(() => {
    if (onChange) {
      onChange(currency);
    }
  }, [currency, onChange]);

  return (
    <Select
      label={t(
        'partnerAndCompany.content.changeCurrency.content.input.label.currency'
      )}
      formValidationProps={{ errorTooltipPlacement: 'right' }}
      name={FieldName.Currency}
      options={options}
      disabled={disabled}
    />
  );
};
