import classNames from 'classnames';
import { ButtonSize, ButtonTheme, ButtonTextColor } from '../const';
import styles from '../Button.module.scss';
import { LinkStyledProp } from '../types';

const mapButtonTextColorToStyle: Record<ButtonTextColor, string> = {
  [ButtonTextColor.White]: styles.whiteText,
  [ButtonTextColor.Blue]: styles.blueText,
  [ButtonTextColor.Red]: styles.redText,
  [ButtonTextColor.BlueGrey]: styles.blueGreyText,
  [ButtonTextColor.RedGrey]: styles.redGreyText,
  [ButtonTextColor.Grey]: styles.greyText,
};

const mapSizeToStyle: Record<ButtonSize, string> = {
  [ButtonSize.Large]: styles.large,
  [ButtonSize.Medium]: styles.medium,
  [ButtonSize.Small]: styles.small,
};

const mapButtonThemeToStyle: Record<ButtonTheme, string> = {
  [ButtonTheme.Blue]: classNames(styles.blueButton, styles.whiteText),
  [ButtonTheme.BlueLight]: classNames(styles.blueLightButton, styles.blueText),
  [ButtonTheme.BlueGhost]: classNames(styles.blueGhostButton, styles.blueText),
  [ButtonTheme.BlueGrey]: classNames(
    styles.blueGreyButton,
    styles.blueGreyText
  ),
  [ButtonTheme.Red]: classNames(styles.redButton, styles.whiteText),
  [ButtonTheme.RedLight]: classNames(styles.redLightButton, styles.redText),
  [ButtonTheme.RedGhost]: classNames(styles.redGhostButton, styles.redText),
  [ButtonTheme.RedGrey]: classNames(styles.redGreyButton, styles.redGreyText),
  [ButtonTheme.Grey]: classNames(styles.greyButton, styles.greyText),
};

const getLinkStyledStyles = (linkStyledProp?: LinkStyledProp): string => {
  const defaultColor = mapButtonTextColorToStyle[ButtonTextColor.Blue];

  if (typeof linkStyledProp === 'object') {
    return classNames(
      linkStyledProp.inline ? styles.inlineLinkStyled : styles.linkStyled,
      linkStyledProp.color
        ? mapButtonTextColorToStyle[linkStyledProp.color]
        : defaultColor
    );
  }

  return linkStyledProp ? classNames(styles.linkStyled, defaultColor) : '';
};

export const getButtonStyles = (
  theme: ButtonTheme,
  size: ButtonSize,
  linkStyledProp?: LinkStyledProp
) => {
  const stylesArr: Array<string> = [styles.commonStyles, mapSizeToStyle[size]];

  if (linkStyledProp) {
    stylesArr.push(getLinkStyledStyles(linkStyledProp));
  } else {
    stylesArr.push(styles.button, mapButtonThemeToStyle[theme]);
  }

  return classNames(...stylesArr);
};
