import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'ui/Table';
import { Loader } from 'ui/Loader';
import { Error } from 'ui/Error';
import { UserTypeName } from 'types';
import { useLoadMore } from 'hooks/useLoadMore';
import { usePromoCodeDetailsModal } from 'pages/Media/components/MediaDataBlock/components/PromoCodeMediaTable/hooks/usePromoCodeDetailsModal';
import { useGetUserTypeNameQuery } from 'hooks/queryHooks/useGetUserTypeNameQuery';
import { usePromoCodeMediaItemsColumns } from './hooks/usePromoCodeMediaItemsColumns';
import { useGetPromoCodeMediaItemsQuery } from './hooks/useGetPromoCodeMediaItemsQuery';
import { PromoCodeMediaItemData } from './queries/fragments/generated/PromoCodeMediaItemData';

export const PromoCodeMediaTable: FC = () => {
  const { openDetailsModal } = usePromoCodeDetailsModal();
  const { t } = useTranslation('media');

  const {
    userTypeName,
    loading: getUserTypeLoading,
    error: getUserTypeError,
  } = useGetUserTypeNameQuery();

  const {
    promoCodeMediaItems,
    fetchMore,
    pageInfo,
    loading: getPromoCodeMediaItemLoading,
    error: getPromoCodeMediaItemsError,
  } = useGetPromoCodeMediaItemsQuery();

  const loadMore = useLoadMore(fetchMore, pageInfo);

  const isCompany = userTypeName === UserTypeName.Company;

  const error = getUserTypeError || getPromoCodeMediaItemsError;

  const columns = usePromoCodeMediaItemsColumns({
    isCompany,
  });

  const handleRowClick = ({ id }: PromoCodeMediaItemData) => {
    openDetailsModal(id);
  };

  if (
    (!promoCodeMediaItems && getPromoCodeMediaItemLoading) ||
    getUserTypeLoading
  ) {
    return <Loader />;
  }

  if (error || promoCodeMediaItems === undefined) {
    return <Error error={error} />;
  }

  return (
    <Table
      columns={columns}
      data={promoCodeMediaItems}
      emptyText={t('placeholder.promoCodes.noData')}
      isLoading={getPromoCodeMediaItemLoading}
      hasMoreData={!!pageInfo?.hasNextPage}
      fetchMore={loadMore}
      onRowClick={handleRowClick}
    />
  );
};
