import { useAuth } from 'components/auth';
import { useGetStatisticDetailedItemName } from '../query/generated/GetStatisticDetailedItemName';

interface useGetStatisticDetailedItemNameQueryParams {
  referralLinkMediaItemId?: string;
}

export const useGetStatisticDetailedItemNameQuery = ({
  referralLinkMediaItemId,
}: useGetStatisticDetailedItemNameQueryParams) => {
  const { user } = useAuth();

  const { data, loading, error } = useGetStatisticDetailedItemName({
    variables: {
      userId: user.id,
      mediaItemId: referralLinkMediaItemId ?? '',
    },
    fetchPolicy: 'cache-first',
    skip: !referralLinkMediaItemId,
  });

  const statisticReferralLinkMediaItemName =
    data?.user.referralLinkMediaItem.name;

  return {
    statisticReferralLinkMediaItemName,
    loading,
    error,
  };
};
