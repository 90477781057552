import { FC } from 'react';
import { useOutletContext } from 'react-router';
import { Loader } from 'ui/Loader';
import { Error } from 'ui/Error';
import { SubHeaderBlock } from 'components/SubHeaderBlock';
import { DatePickerWithRangeButton } from 'ui/DatePickerWithRangeButton';
import { DropdownPosition } from 'ui/Dropdown';
import { useTranslation } from 'react-i18next';
import { ReactComponent as StatisticIcon } from 'assets/icons/colors/Chart.svg';
import { TopBar } from 'components/TopBar';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { StatisticTableByType } from './components/StatisticTableByType';
import { StatisticOutletContext } from '../../types';
import { useStatisticDateRange } from '../../hooks/useStatisticDateRange';
import { StatisticNavLinksList } from './components/StatisticNavLinksList';

export const StatisticGeneral: FC = () => {
  const { t } = useTranslation('statistic');
  const { statisticType } = useOutletContext<StatisticOutletContext>();
  const translationUserType = useTranslationUserType();

  const { updateDateRange, loading, error, dateRange, defaultDateRange } =
    useStatisticDateRange();

  if (loading) {
    return <Loader />;
  }

  if (error || !dateRange || !defaultDateRange || !statisticType) {
    return <Error />;
  }

  const [startDate, endDate] = dateRange;

  return (
    <>
      <TopBar
        title={t(`${translationUserType}.content.header.header`)}
        icon={StatisticIcon}
      />
      <SubHeaderBlock>
        <StatisticNavLinksList />
        <DatePickerWithRangeButton
          onDateSubmit={updateDateRange}
          startDateInitial={startDate}
          endDateInitial={endDate}
          dateResetTo={defaultDateRange}
          dropdownPosition={DropdownPosition.RightBottom}
        />
      </SubHeaderBlock>
      <StatisticTableByType
        statisticType={statisticType}
        nonNullableDateRange={dateRange}
      />
    </>
  );
};
