import { ErrorCode } from 'const';
import { getNotUniqueErrorTranslations } from './getNotUniqueErrorTranslations';
import { getIncorrectValueErrorTranslations } from './getIncorrectValueErrorTranslations';

interface ErrorHandlerConfig {
  errorCode: ErrorCode;
  getTranslations: (isCompany: boolean) => Record<string, string>;
}

export const errorHandlers: Array<ErrorHandlerConfig> = [
  {
    errorCode: ErrorCode.NotUnique,
    getTranslations: getNotUniqueErrorTranslations,
  },
  {
    errorCode: ErrorCode.InvalidArgument,
    getTranslations: getIncorrectValueErrorTranslations,
  },
];
