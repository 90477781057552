import { FC } from 'react';
import { useLocation, useOutletContext, useParams } from 'react-router';
import { AppRouteParams } from 'utils/tsUtils/extractParamsFromAppRoute';
import { Loader } from 'ui/Loader';
import { Error } from 'ui/Error';
import { DatePickerWithRangeButton } from 'ui/DatePickerWithRangeButton';
import { SubHeaderBlock } from 'components/SubHeaderBlock';
import { RouterBreadcrumbs } from 'components/RouterBreadcrumbs';
import { DropdownPosition } from 'ui/Dropdown';
import { useGetStatisticDetailedItemNameQuery } from './hooks/useGetStatisticDetailedItemNameQuery';
import { useRedirectToNotFound } from './hooks/useRedirectToNotFound';
import { StatisticDetailedByType } from './components/StatisticDetailedByType';
import { StatisticOutletContext } from '../../types';
import { useStatisticDateRange } from '../../hooks/useStatisticDateRange';
import styles from './StatisticDetailed.module.scss';

export const StatisticDetailed: FC = () => {
  const { referralLinkMediaItemId } =
    useParams<
      AppRouteParams['Lang']['Statistic']['RevShare' | 'Cpa']['MediaItem']
    >();

  const { pathname } = useLocation();
  const { statisticType } = useOutletContext<StatisticOutletContext>();

  const {
    updateDateRange,
    loading: dateRangeLoading,
    error: dateRangeError,
    dateRange,
    defaultDateRange,
  } = useStatisticDateRange();

  const {
    statisticReferralLinkMediaItemName,
    error: mediaItemNameError,
    loading: mediaItemNameLoading,
  } = useGetStatisticDetailedItemNameQuery({ referralLinkMediaItemId });

  useRedirectToNotFound(mediaItemNameError);

  if (mediaItemNameLoading || dateRangeLoading) {
    return <Loader />;
  }

  const dateError = !dateRange || !defaultDateRange || dateRangeError;

  if (
    !referralLinkMediaItemId ||
    !statisticReferralLinkMediaItemName ||
    !statisticType ||
    mediaItemNameError ||
    dateError
  ) {
    return <Error />;
  }

  const [startDate, endDate] = dateRange;

  return (
    <>
      <RouterBreadcrumbs
        customLabelsForCrumbs={{
          [pathname]: statisticReferralLinkMediaItemName,
        }}
        className={styles.breadcrumbsContainer}
      />
      <SubHeaderBlock>
        <h2>{statisticReferralLinkMediaItemName}</h2>
        <DatePickerWithRangeButton
          onDateSubmit={updateDateRange}
          startDateInitial={startDate}
          endDateInitial={endDate}
          dateResetTo={defaultDateRange}
          dropdownPosition={DropdownPosition.RightBottom}
        />
      </SubHeaderBlock>
      <StatisticDetailedByType
        referralLinkMediaItemId={referralLinkMediaItemId}
        type={statisticType}
        nonNullableDateRange={dateRange}
      />
    </>
  );
};
