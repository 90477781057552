import { defaultLanguageCode } from 'const';
import { Language } from 'types/generated/gql';
import { mapLanguageToPath } from 'utils/language/mapLanguageToPath';
import { normalizePath } from './normalizePath';

type ForbiddenPrefixes = '/' | `${Language}${'/'}`;

type AllowedPath<S extends string> = S extends `${ForbiddenPrefixes}${string}`
  ? never
  : S;

export const addBasePath = <S extends string>(
  path: AllowedPath<S>,
  language: Language = defaultLanguageCode,
  search = ''
) => normalizePath(`/${mapLanguageToPath(language)}/${path}${search}`);
