import { ReactNode } from 'react';
import { Column, Row } from '@tanstack/react-table';
import { useInfiniteScroll } from 'hooks/useInfiniteScroll';
import { TableRow } from '../TableRow';
import styles from './TableBody.module.scss';
import { TableSummaryRow } from '../TableSummaryRow';

interface TableBodyProps<TData> {
  fetchMore: () => void;
  rows: Array<Row<TData>>;
  columns: Array<Column<TData>>;
  summaryRow?: Array<ReactNode>;
  onRowClick?: (arg: TData) => void;
}

export const TableBody = <TData,>({
  fetchMore,
  rows,
  columns,
  summaryRow,
  onRowClick,
}: TableBodyProps<TData>) => {
  const loadTrigger = useInfiniteScroll(fetchMore);

  return (
    <div className={styles.container} role="rowgroup">
      {summaryRow && <TableSummaryRow row={summaryRow} columns={columns} />}
      {rows.map((row) => (
        <TableRow key={row.id} row={row} onClick={onRowClick} />
      ))}
      {loadTrigger}
    </div>
  );
};
