import { t } from 'i18n';

type TranslationKeys = 'email' | 'website';

export const getIncorrectValueErrorTranslations = (
  isCompany: boolean
): Record<TranslationKeys, string> => ({
  email: isCompany
    ? t('auth:signup.company.input.registration.validation.invalidEmail')
    : t('auth:signup.partner.input.registration.validation.invalidEmail'),
  website: t(
    'auth:signup.company.input.registration.validation.invalidWebsiteFormat'
  ),
});
