import { FC } from 'react';
import { Icon, IconSize } from 'ui/Icon';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { Button, ButtonSize } from 'ui/Button';
import { ReactComponent as LoadingIcon } from 'assets/icons/solid/Loader.svg';
import { ReactComponent as Flag } from 'assets/icons/line/Flag.svg';
import { ReactComponent as Update } from 'assets/icons/line/Refresh.svg';
import styles from './LoadMoreStatus.module.scss';

interface LoadingStatusProps {
  isLoading: boolean;
  hasMoreData: boolean;
  error?: ApolloError;
  fetchMore?: () => void;
}

export const LoadMoreStatus: FC<LoadingStatusProps> = ({
  isLoading,
  hasMoreData,
  error,
  fetchMore,
}) => {
  const { t } = useTranslation();

  if (error) {
    return (
      <div className={styles.statusContainer}>
        <span className={styles.errorMessage}>
          {t('progress.loadingErrorWidget')}
        </span>
        {fetchMore && (
          <Button
            className={styles.updateButton}
            linkStyled
            iconConfig={{
              iconComponent: Update,
              before: true,
            }}
            onClick={() => {
              fetchMore?.();
            }}
            size={ButtonSize.Medium}
          >
            {t('progress.refresh')}
          </Button>
        )}
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className={styles.statusContainer}>
        <Icon
          iconClassName={styles.loadingIcon}
          iconComponent={LoadingIcon}
          hasDefaultColor={false}
          size={IconSize.Small}
          spin
        />
        <span className={styles.text}>{t('progress.loading')}</span>
      </div>
    );
  }

  if (!hasMoreData) {
    return (
      <div className={styles.statusContainer}>
        <Icon
          iconClassName={styles.flagIcon}
          iconComponent={Flag}
          hasDefaultColor={false}
          size={IconSize.Small}
        />
        <span className={styles.text}>{t('progress.allDataLoaded')}</span>
      </div>
    );
  }

  return null;
};
