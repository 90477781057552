import { ApolloError } from '@apollo/client';
import { ErrorCode } from 'const';
import { FormikErrors } from 'formik';
import { getGraphQLErrorFieldPath } from 'utils/getGraphQLErrorFieldPath';

interface MapErrorToFieldParams<T> {
  apolloError: ApolloError;
  errorCode: ErrorCode;
  getTranslations: (isCompany: boolean) => Record<string, string>;
  isCompany: boolean;
  errors: FormikErrors<T>;
}

export const mapErrorToField = <T>({
  apolloError,
  errorCode,
  getTranslations,
  isCompany,
  errors,
}: MapErrorToFieldParams<T>): FormikErrors<T> => {
  const updatedErrors = { ...errors };
  const translations = getTranslations(isCompany);
  const fieldPath = getGraphQLErrorFieldPath(apolloError, errorCode);

  if (fieldPath) {
    const fieldValue = Object.values(fieldPath)[0];

    if (fieldValue in translations) {
      const translationValue = translations[fieldValue];

      updatedErrors[fieldValue as keyof T] =
        translationValue as FormikErrors<T>[keyof T];
    }
  }

  return updatedErrors;
};
