export enum ErrorCode {
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  NotUnique = 'RESOURCE_NOT_UNIQUE',
  NotFound = 'RESOURCE_NOT_FOUND',
  InvalidArgument = 'INVALID_ARGUMENT',
  InvalidInputData = 'INVALID_INPUT_DATA',
  GraphQLInvalidArguments = 'GRAPHQL_INVALID_ARGUMENTS',
  GraphQLSyntaxError = 'GRAPHQL_SYNTAX_ERROR',
  Unknown = 'UNKNOWN_ERROR',
}
