import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useModalContext, openModal } from 'ui/Modal';
import {
  ConfirmModal,
  ConfirmModalContent,
  ConfirmModalContextProvider,
} from 'ui/ConfirmModal';
import { EditingIdsContextProvider } from 'contexts/EditingIdsContext';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { EditReferralLinkMediaModal } from '../components/EditReferralLinkMediaModal';
import { CreateMediaModal } from '../components/CreateMediaModal';
import { EditTab } from '../components/EditReferralLinkMediaModal/const';

export const useOpenReferralLinkModal = () => {
  const { t } = useTranslation(['common', 'media']);
  const { dispatch } = useModalContext();
  const translationUserType = useTranslationUserType();

  const confirmModalContent: ConfirmModalContent = useMemo(
    () => ({
      cancelButton: t(`media:${translationUserType}.action.modal.done`),
      confirmButton: t(`media:${translationUserType}.action.modal.cancel`),
      text: t(`media:${translationUserType}.content.modal.description`),
      title: t(`media:${translationUserType}.content.modal.title`),
    }),
    [t, translationUserType]
  );

  const openCreateModal = useCallback(() => {
    dispatch(
      openModal({
        title: t(
          `media:${translationUserType}.content.drawer.createMedia.header.title`
        ),
        content: (
          <ConfirmModalContextProvider>
            <CreateMediaModal />
            <ConfirmModal content={confirmModalContent} />
          </ConfirmModalContextProvider>
        ),
      })
    );
  }, [confirmModalContent, dispatch, t, translationUserType]);

  const openEditModal = useCallback(
    (referralLinkMediaItemId: string, defaultActiveTab?: EditTab) => {
      dispatch(
        openModal({
          title: t(
            `media:${translationUserType}.content.drawer.details.header.title`
          ),
          subTitle: `#${referralLinkMediaItemId}`,
          content: (
            <EditingIdsContextProvider>
              <ConfirmModalContextProvider>
                <EditReferralLinkMediaModal
                  referralLinkMediaItemId={referralLinkMediaItemId}
                  defaultActiveTab={defaultActiveTab}
                />
                <ConfirmModal content={confirmModalContent} />
              </ConfirmModalContextProvider>
            </EditingIdsContextProvider>
          ),
        })
      );
    },
    [confirmModalContent, dispatch, t, translationUserType]
  );

  return { openCreateModal, openEditModal };
};
