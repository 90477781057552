import { useTranslation } from 'react-i18next';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { PaymentModel } from 'components/PaymentModel';
import { CopyLink } from 'ui/CopyLink';
import { TableCellAlign } from 'ui/Table/components/TableColumnCell/types';
import { MultilineCellWithIcon } from 'ui/Table/components/MultilineCellWithIcon';
import { UserTypeName } from 'types';
import { MayBeNullCell } from 'ui/Table/components/MayBeNullCell';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { ReferralLinkMediaItemBasicData } from '../../../../../queries/fragments/generated/ReferralLinkMediaItemBasicData';
import { useOpenReferralLinkModal } from '../../../../../hooks/useOpenReferralLinkModal';
import { EditTab } from '../../../../EditReferralLinkMediaModal/const';
import { PostbackButton } from '../components/PostbackButton';

const COLUMNS_SIZES = {
  nameAndId: 232,
  ownerSize: 232,
  linkMinWidth: 392,
  tariffSize: 140,
  paymentModel: 160,
  postbackSize: 116,
} as const;

interface UseReferralLinkMediaTableColumns {
  isCompany: boolean;
}

export const useReferralLinkMediaTableColumns = ({
  isCompany,
}: UseReferralLinkMediaTableColumns): Array<
  ColumnDef<ReferralLinkMediaItemBasicData>
> => {
  const { t } = useTranslation(['common', 'media']);
  const translationUserType = useTranslationUserType();

  const { openEditModal } = useOpenReferralLinkModal();

  const columnHelper = createColumnHelper<ReferralLinkMediaItemBasicData>();

  /*
   *  We need to use any as generic argument while bug won't be fixed
   *  Issue https://github.com/TanStack/table/issues/4382
   */
  const columns: Array<ColumnDef<ReferralLinkMediaItemBasicData, any>> = [
    columnHelper.accessor('id', {
      header: t(
        `media:${translationUserType}.content.dataTable.links.column.name`
      ),
      cell: ({ row }) => (
        <MultilineCellWithIcon
          title={row.original.name}
          subTitle={row.original.id}
        />
      ),
      size: COLUMNS_SIZES.nameAndId,
      enablePinning: true,
    }),
    ...(isCompany
      ? [
          columnHelper.accessor('owner', {
            header: t('media:company.content.dataTable.links.column.owner'),
            cell: ({ row }) => {
              if (row.original.owner.__typename === UserTypeName.Company) {
                return (
                  <MultilineCellWithIcon
                    title={row.original.owner.name}
                    subTitle={t(
                      'media:company.content.dataTable.links.value.ownerType'
                    )}
                  />
                );
              }

              if (row.original.owner.__typename === 'CompanyPartner') {
                return row.original.owner.username;
              }

              return <MayBeNullCell />;
            },
            size: COLUMNS_SIZES.ownerSize,
          }),
        ]
      : []),
    columnHelper.accessor('link', {
      header: t(
        `media:${translationUserType}.content.dataTable.links.column.link`
      ),
      cell: (info) => (
        <CopyLink
          onClick={(e) => e.stopPropagation()}
          target="_blank"
          to={info.getValue()}
          tooltipText={t('linkCopied')}
        >
          {info.getValue()}
        </CopyLink>
      ),
      minSize: COLUMNS_SIZES.linkMinWidth,
    }),
    columnHelper.accessor('tariff', {
      header: t(
        `media:${translationUserType}.content.dataTable.links.column.tariff`
      ),
      cell: ({ row }) => row.original.tariff.name,
      size: COLUMNS_SIZES.tariffSize,
    }),
    columnHelper.accessor('tariff', {
      id: 'paymentModel',
      header: t(
        `media:${translationUserType}.content.dataTable.links.column.paymentType`
      ),
      cell: ({ row }) => (
        <PaymentModel tariffTypeName={row.original.tariff.__typename} />
      ),
      size: COLUMNS_SIZES.paymentModel,
    }),
    columnHelper.accessor('postbackInfo', {
      header: t(
        `media:${translationUserType}.content.dataTable.links.column.postback`
      ),
      cell: ({ row }) => (
        <PostbackButton
          status={row.original.postbackInfo.commonPostbackStatus}
          onClick={(e) => {
            e.stopPropagation();
            openEditModal(row.original.id, EditTab.Postbacks);
          }}
        />
      ),
      meta: {
        align: TableCellAlign.Center,
      },
      size: COLUMNS_SIZES.postbackSize,
    }),
  ];

  return columns;
};
