import { flexRender, HeaderGroup } from '@tanstack/react-table';
import { calculateLeftOffset } from 'ui/Table/helpers/calculateLeftOffset';
import { TableColumnCell } from '../TableColumnCell';
import styles from './TableHeader.module.scss';

interface TableHeaderProps<TData> {
  headerGroups: Array<HeaderGroup<TData>>;
}

export const TableHeader = <TData,>({
  headerGroups,
}: TableHeaderProps<TData>) => (
  <div className={styles.container} role="rowgroup">
    {headerGroups.map((headerGroup) => {
      let nonPinnedColumnsWidth = 0;

      return (
        <div key={headerGroup.id} className={styles.groupContainer} role="row">
          {headerGroup.headers.map((header) => {
            const { enablePinning, meta } = header.column.columnDef;

            const { left, newNonPinnedColumnsWidth } = calculateLeftOffset({
              column: header.column,
              nonPinnedColumnsWidth,
            });

            nonPinnedColumnsWidth = newNonPinnedColumnsWidth;

            return (
              <TableColumnCell
                key={header.id}
                isPinned={enablePinning}
                left={left}
                align={meta?.align}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </TableColumnCell>
            );
          })}
        </div>
      );
    })}
  </div>
);
