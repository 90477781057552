import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { ReferralLinkMediaItemData } from '../../../../queries/fragments/generated/ReferralLinkMediaItemData';
import { ReferralLinkMediaItemBasicData } from '../../../../queries/fragments/generated/ReferralLinkMediaItemBasicData';
import { TariffForMediaBasicData } from '../../../../queries/fragments/generated/TariffForMediaBasicData';
import { TariffForMediaData } from '../../../../queries/fragments/generated/TariffForMediaData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetReferralLinkMediaItemVariables = SchemaTypes.Exact<{
  referralLinkMediaItemId: SchemaTypes.Scalars['ID']['input'];
  userId: SchemaTypes.Scalars['ID']['input'];
}>;

export type GetReferralLinkMediaItem = {
  __typename: 'Query';
  user:
    | {
        __typename: 'Company';
        id: string;
        referralLinkMediaItem: {
          __typename: 'ReferralLinkMediaItem';
          createdAt: string;
          link: string;
          id: string;
          name: string;
          tariff:
            | {
                __typename: 'CpaTariff';
                geolocation: Array<CommonType.Country>;
                createdAt: string;
                id: string;
                name: string;
                baseline: {
                  __typename: 'MoneyAmount';
                  currency: CommonType.Currency;
                  value: number;
                };
                reward: {
                  __typename: 'MoneyAmount';
                  currency: CommonType.Currency;
                  value: number;
                };
              }
            | {
                __typename: 'RevShareTariff';
                rewardPercent: string;
                createdAt: string;
                id: string;
                name: string;
              };
          postbackInfo: {
            __typename: 'PostbackInfo';
            commonPostbackStatus: SchemaTypes.CommonPostbackStatus;
          };
          owner:
            | { __typename: 'Company'; id: string; name: string }
            | { __typename: 'CompanyPartner'; id: string; username: string }
            | { __typename: 'Partner'; id: string; username: string };
        };
      }
    | {
        __typename: 'Partner';
        id: string;
        referralLinkMediaItem: {
          __typename: 'ReferralLinkMediaItem';
          createdAt: string;
          link: string;
          id: string;
          name: string;
          tariff:
            | {
                __typename: 'CpaTariff';
                geolocation: Array<CommonType.Country>;
                createdAt: string;
                id: string;
                name: string;
                baseline: {
                  __typename: 'MoneyAmount';
                  currency: CommonType.Currency;
                  value: number;
                };
                reward: {
                  __typename: 'MoneyAmount';
                  currency: CommonType.Currency;
                  value: number;
                };
              }
            | {
                __typename: 'RevShareTariff';
                rewardPercent: string;
                createdAt: string;
                id: string;
                name: string;
              };
          postbackInfo: {
            __typename: 'PostbackInfo';
            commonPostbackStatus: SchemaTypes.CommonPostbackStatus;
          };
          owner:
            | { __typename: 'Company'; id: string; name: string }
            | { __typename: 'CompanyPartner'; id: string; username: string }
            | { __typename: 'Partner'; id: string; username: string };
        };
      };
};

export const GetReferralLinkMediaItemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetReferralLinkMediaItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'referralLinkMediaItemId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Partner' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'referralLinkMediaItem' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'referralLinkMediaItemId',
                              },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'ReferralLinkMediaItem',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'ReferralLinkMediaItemData',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Company' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'referralLinkMediaItem' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'referralLinkMediaItemId',
                              },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'ReferralLinkMediaItem',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'ReferralLinkMediaItemData',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ReferralLinkMediaItemData.definitions,
    ...ReferralLinkMediaItemBasicData.definitions,
    ...TariffForMediaBasicData.definitions,
    ...TariffForMediaData.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetReferralLinkMediaItem__
 *
 * To run a query within a React component, call `useGetReferralLinkMediaItem` and pass it any options that fit your needs.
 * When your component renders, `useGetReferralLinkMediaItem` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferralLinkMediaItem({
 *   variables: {
 *      referralLinkMediaItemId: // value for 'referralLinkMediaItemId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetReferralLinkMediaItem(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetReferralLinkMediaItem,
    GetReferralLinkMediaItemVariables
  > &
    (
      | { variables: GetReferralLinkMediaItemVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetReferralLinkMediaItem,
    GetReferralLinkMediaItemVariables
  >(GetReferralLinkMediaItemDocument, options);
}
export function useGetReferralLinkMediaItemLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetReferralLinkMediaItem,
    GetReferralLinkMediaItemVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetReferralLinkMediaItem,
    GetReferralLinkMediaItemVariables
  >(GetReferralLinkMediaItemDocument, options);
}
export function useGetReferralLinkMediaItemSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetReferralLinkMediaItem,
    GetReferralLinkMediaItemVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetReferralLinkMediaItem,
    GetReferralLinkMediaItemVariables
  >(GetReferralLinkMediaItemDocument, options);
}
export type GetReferralLinkMediaItemHookResult = ReturnType<
  typeof useGetReferralLinkMediaItem
>;
export type GetReferralLinkMediaItemLazyQueryHookResult = ReturnType<
  typeof useGetReferralLinkMediaItemLazyQuery
>;
export type GetReferralLinkMediaItemSuspenseQueryHookResult = ReturnType<
  typeof useGetReferralLinkMediaItemSuspenseQuery
>;
export type GetReferralLinkMediaItemQueryResult = Apollo.QueryResult<
  GetReferralLinkMediaItem,
  GetReferralLinkMediaItemVariables
>;
