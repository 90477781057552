import { Column } from '@tanstack/react-table';

interface CalculateLeftOffsetParams<TData> {
  column: Column<TData>;
  nonPinnedColumnsWidth: number;
}

export const calculateLeftOffset = <TData>({
  column,
  nonPinnedColumnsWidth,
}: CalculateLeftOffsetParams<TData>) => {
  let newNonPinnedColumnsWidth = nonPinnedColumnsWidth;
  const { enablePinning, size, minSize } = column.columnDef;

  const initialLeftOffset = column.getStart();

  if (!enablePinning) {
    if (newNonPinnedColumnsWidth !== 0) {
      newNonPinnedColumnsWidth = 0;
    }

    newNonPinnedColumnsWidth += size ?? minSize ?? 0;
  }

  const left = enablePinning
    ? initialLeftOffset - nonPinnedColumnsWidth
    : undefined;

  return { left, newNonPinnedColumnsWidth };
};
