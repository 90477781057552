import { FC } from 'react';
import { useLoadMore } from 'hooks/useLoadMore';
import { Table } from 'ui/Table';
import { Error } from 'ui/Error';
import { Loader } from 'ui/Loader';
import { useTranslation } from 'react-i18next';
import { UserTypeName } from 'types';
import { useGetUserTypeNameQuery } from 'hooks/queryHooks/useGetUserTypeNameQuery';
import { useOpenReferralLinkModal } from '../../../../hooks/useOpenReferralLinkModal';
import { ReferralLinkMediaItemBasicData } from '../../../../queries/fragments/generated/ReferralLinkMediaItemBasicData';
import { useGetReferralLinkMediaItemsQuery } from './hooks/useGetReferralLinkMediaItemsQuery';
import { useReferralLinkMediaTableColumns } from './hooks/useReferralLinkMediaTableColumns';

export const ReferralLinkMediaTable: FC = () => {
  const { openEditModal } = useOpenReferralLinkModal();
  const { t } = useTranslation('media');

  const {
    userTypeName,
    loading: getUserTypeLoading,
    error: getUserTypeError,
  } = useGetUserTypeNameQuery();

  const {
    referralLinkMediaItems,
    pageInfo,
    fetchMore,
    isSliced,
    error: getReferralLinkMediaItemsError,
    loading: getReferralLinkMediaItemsLoading,
  } = useGetReferralLinkMediaItemsQuery();

  const columns = useReferralLinkMediaTableColumns({
    isCompany: userTypeName === UserTypeName.Company,
  });

  const loadMore = useLoadMore(fetchMore, pageInfo);

  const handleRowClick = ({ id }: ReferralLinkMediaItemBasicData) => {
    openEditModal(id);
  };

  const isLoading =
    (!referralLinkMediaItems && getReferralLinkMediaItemsLoading) ||
    getUserTypeLoading;
  const error = getUserTypeError || getReferralLinkMediaItemsError;

  if (isLoading) {
    return <Loader shouldExcludeMenuWidth />;
  }

  if (error || referralLinkMediaItems === undefined) {
    return <Error error={error} />;
  }

  return (
    <Table
      onRowClick={handleRowClick}
      columns={columns}
      data={referralLinkMediaItems}
      emptyText={t('placeholder.links.noData')}
      isLoading={getReferralLinkMediaItemsLoading || isSliced}
      hasMoreData={!!pageInfo?.hasNextPage}
      error={getReferralLinkMediaItemsError}
      fetchMore={loadMore}
    />
  );
};
