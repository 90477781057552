import { useAuth } from 'components/auth';
import { useGetPromoCodeMediaItem } from '../queries/generated/GetPromoCodeMediaItem';

interface UseGetPromoCodeMediaItemQuery {
  promoCodeMediaItemId: string;
}

export const useGetPromoCodeMediaItemQuery = ({
  promoCodeMediaItemId,
}: UseGetPromoCodeMediaItemQuery) => {
  const { user } = useAuth();
  const { data, loading, error } = useGetPromoCodeMediaItem({
    variables: {
      userId: user.id,
      promoCodeMediaItemId,
    },
  });

  const promoCodeMediaItem = data?.user.promoCodeMediaItem;

  return {
    promoCodeMediaItem,
    loading,
    error,
  };
};
