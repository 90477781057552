import { ReactNode } from 'react';
import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { ApolloError } from '@apollo/client';
import { EmptyDataBlockWithContainer } from 'components/EmptyDataBlockWithContainer';
import { TableBody } from './components/TableBody';
import { TableHeader } from './components/TableHeader';
import { LoadMoreStatus } from './components/LoadMoreStatus';
import styles from './Table.module.scss';
import { getGridTemplateColumns } from './helpers';
import { SearchBlock } from './components/SearchBlock';

interface TableProps<TData> {
  columns: Array<ColumnDef<TData>>;
  data: Array<TData>;
  isLoading: boolean;
  hasMoreData: boolean;
  fetchMore: () => void;
  emptyText: string;
  error?: ApolloError;
  summaryRow?: Array<ReactNode>;
  onRowClick?: (arg: TData) => void;
  searchComponent?: ReactNode;
}

export const Table = <TData,>({
  columns,
  data,
  isLoading,
  hasMoreData,
  fetchMore,
  error,
  summaryRow,
  onRowClick,
  emptyText,
  searchComponent,
}: TableProps<TData>) => {
  const tableInstance = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: undefined,
      minSize: undefined,
    },
  });

  const isEmpty = data.length === 0;

  const gridTemplateColumns = getGridTemplateColumns(
    tableInstance.getAllColumns()
  );

  if (isEmpty) {
    return <EmptyDataBlockWithContainer emptyText={emptyText} />;
  }

  return (
    <div className={styles.container} role="table">
      <div className={styles.content}>
        <SearchBlock searchComponent={searchComponent} />
        <div
          className={styles.table}
          style={{
            gridTemplateColumns,
          }}
        >
          <TableHeader headerGroups={tableInstance.getHeaderGroups()} />
          <TableBody
            summaryRow={summaryRow}
            columns={tableInstance.getAllColumns()}
            rows={tableInstance.getRowModel().rows}
            fetchMore={fetchMore}
            onRowClick={onRowClick}
          />
        </div>
      </div>
      <div className={styles.loadMoreStatusContainer}>
        <LoadMoreStatus
          isLoading={isLoading}
          fetchMore={fetchMore}
          hasMoreData={hasMoreData}
          error={error}
        />
      </div>
    </div>
  );
};
