import { useState } from 'react';
import { DatePickerHandleProps, DatePickerRange } from 'ui/DatePicker';
import { useTranslation } from 'react-i18next';
import {
  getDateTitle,
  getRangeAllTime,
  getRangeLastMonth,
  getRangeLastWeek,
} from '../helpers';
import { DateRanges } from '../const';
import { normalizeDateRange } from '../helpers/normalizeDateRange';
import { getRangeToday } from '../helpers/getRangeToday';
import { getRangeYesterday } from '../helpers/getRangeYesterday';
import { getRangeLastReportingPeriod } from '../helpers/getRangeLastReportingPeriod';

interface UseDatePickerWithRange {
  onDateSubmit: (date: DatePickerRange) => void;
  setActiveCustomRange: (range: DateRanges | null) => void;
  activeCustomRange?: DateRanges | null;
  minDateInitial?: Date | null;
  startDateInitial?: Date | null;
  endDateInitial?: Date | null;
  dateResetTo?: DatePickerHandleProps;
}

export const useDatePickerWithRange = ({
  minDateInitial,
  startDateInitial,
  endDateInitial,
  onDateSubmit,
  activeCustomRange,
  setActiveCustomRange,
  dateResetTo,
}: UseDatePickerWithRange) => {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState(startDateInitial || null);
  const [endDate, setEndDate] = useState(endDateInitial || null);

  const handleSubmit = (dates: DatePickerHandleProps) => {
    if (!Array.isArray(dates)) {
      return;
    }

    const normalizedDateRange = normalizeDateRange(dates);
    const [startDateSubmit, endDateSubmit] = normalizedDateRange;

    if (startDateSubmit && endDateSubmit) {
      onDateSubmit(normalizedDateRange);
    }

    if (!startDateSubmit && !endDateSubmit) {
      onDateSubmit(dates);
      setActiveCustomRange(null);
      setStartDate(null);
      setEndDate(null);
    }
  };

  const handleChange = (dates: DatePickerHandleProps) => {
    setActiveCustomRange(null);
    if (Array.isArray(dates)) {
      const [startDateSubmit, endDateSubmit] = dates;

      setStartDate(startDateSubmit);
      setEndDate(endDateSubmit);
    }
  };

  const setDateAllTime = () => {
    if (activeCustomRange !== DateRanges.AllTime) {
      const { startRange, endRange } = getRangeAllTime(minDateInitial);

      setStartDate(startRange);
      setEndDate(endRange);
    }

    setActiveCustomRange(DateRanges.AllTime);
  };

  const setDateCurrentReportingPeriod = () => {
    if (
      activeCustomRange !== DateRanges.CurrentReportingPeriod &&
      Array.isArray(dateResetTo)
    ) {
      const [startNormalizedDefaultDate, endNormalizedDefaultDate] =
        normalizeDateRange(dateResetTo);

      setStartDate(startNormalizedDefaultDate);
      setEndDate(endNormalizedDefaultDate);
    }

    setActiveCustomRange(DateRanges.CurrentReportingPeriod);
  };

  const setDateLastMonth = () => {
    if (activeCustomRange !== DateRanges.LastMonth) {
      const { startRange, endRange } = getRangeLastMonth();

      setStartDate(startRange);
      setEndDate(endRange);
    }

    setActiveCustomRange(DateRanges.LastMonth);
  };

  const setDateLastReportingPeriod = () => {
    if (activeCustomRange !== DateRanges.LastReportingPeriod) {
      const { startRange, endRange } = getRangeLastReportingPeriod();

      setStartDate(startRange);
      setEndDate(endRange);
    }

    setActiveCustomRange(DateRanges.LastReportingPeriod);
  };

  const setDateLastWeek = () => {
    if (activeCustomRange !== DateRanges.LastWeek) {
      const { startRange, endRange } = getRangeLastWeek();

      setStartDate(startRange);
      setEndDate(endRange);
    }

    setActiveCustomRange(DateRanges.LastWeek);
  };

  const setDateYesterday = () => {
    if (activeCustomRange !== DateRanges.Yesterday) {
      const { startRange, endRange } = getRangeYesterday();

      setStartDate(startRange);
      setEndDate(endRange);
    }

    setActiveCustomRange(DateRanges.Yesterday);
  };

  const setDateToday = () => {
    if (activeCustomRange !== DateRanges.Today) {
      const { startRange, endRange } = getRangeToday();

      setStartDate(startRange);
      setEndDate(endRange);
    }

    setActiveCustomRange(DateRanges.Today);
  };

  const customRanges = [
    {
      onClick: setDateAllTime,
      id: DateRanges.AllTime,
      title: t('datePicker.filter.allTime'),
    },
    {
      onClick: setDateCurrentReportingPeriod,
      id: DateRanges.CurrentReportingPeriod,
      title: t('datePicker.filter.currentReportingPeriod'),
    },
    {
      onClick: setDateLastMonth,
      id: DateRanges.LastMonth,
      title: t('datePicker.filter.lastMonth'),
    },
    {
      onClick: setDateLastReportingPeriod,
      id: DateRanges.LastReportingPeriod,
      title: t('datePicker.filter.lastReportingPeriod'),
    },
    {
      onClick: setDateLastWeek,
      id: DateRanges.LastWeek,
      title: t('datePicker.filter.week'),
    },
    {
      onClick: setDateYesterday,
      id: DateRanges.Yesterday,
      title: t('datePicker.filter.yesterday'),
    },
    {
      onClick: setDateToday,
      id: DateRanges.Today,
      title: t('datePicker.filter.today'),
    },
  ];

  const dateTitleRange = getDateTitle({
    startDate,
    endDate,
  });

  return {
    customRanges,
    activeCustomRange,
    startDate,
    endDate,
    handleSubmit,
    handleChange,
    dateTitleRange,
  };
};
