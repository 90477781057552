import classnames from 'classnames';
import { flexRender, Row } from '@tanstack/react-table';
import { calculateLeftOffset } from 'ui/Table/helpers/calculateLeftOffset';
import styles from './TableRow.module.scss';
import { TableRowCell } from '../TableRowCell';

interface TableRowProps<TData> {
  row: Row<TData>;
  onClick?: (arg: TData) => void;
}

export const TableRow = <TData,>({ row, onClick }: TableRowProps<TData>) => {
  let nonPinnedColumnsWidth = 0;

  const handleRowClick = (arg: TData) => {
    if (onClick && !window.getSelection()?.toString()) {
      onClick(arg);
    }
  };

  return (
    <div
      className={classnames(styles.container, {
        [styles.clickable]: Boolean(onClick),
      })}
      onClick={() => handleRowClick(row.original)}
      role="row"
      aria-hidden
    >
      {row.getVisibleCells().map((cell) => {
        const { meta, enablePinning } = cell.column.columnDef;

        const { left, newNonPinnedColumnsWidth } = calculateLeftOffset({
          column: cell.column,
          nonPinnedColumnsWidth,
        });

        nonPinnedColumnsWidth = newNonPinnedColumnsWidth;

        return (
          <TableRowCell
            key={cell.id}
            isPinned={enablePinning}
            left={left}
            align={meta?.align}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </TableRowCell>
        );
      })}
    </div>
  );
};
