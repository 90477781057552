import { FC } from 'react';
import { Error } from 'ui/Error';
import { Loader } from 'ui/Loader';
import { useGetUserTypeNameQuery } from 'hooks/queryHooks/useGetUserTypeNameQuery';
import { useGetPromoCodeMediaItemQuery } from './hooks/useGetPromoCodeMediaItemQuery';
import { PromoCodeMediaDetails } from './components/PromoCodeMediaDetails';
import { PromoCodeMediaBonus } from './components/PromoCodeMediaBonus';
import { PromoCodeMediaFreespin } from './components/PromoCodeMediaFreespin';
import styles from './PromoCodeDetailsModal.module.scss';

interface PromoCodeDetailsModalProps {
  promoCodeMediaItemId: string;
}

export const PromoCodeDetailsModal: FC<PromoCodeDetailsModalProps> = ({
  promoCodeMediaItemId,
}) => {
  const {
    userTypeName,
    loading: getUserTypeLoading,
    error: getUserTypeError,
  } = useGetUserTypeNameQuery();

  const {
    promoCodeMediaItem,
    loading: getPromoCodeMediaItemLoading,
    error: getPromoCodeMediaItemError,
  } = useGetPromoCodeMediaItemQuery({ promoCodeMediaItemId });

  const isLoading = getPromoCodeMediaItemLoading || getUserTypeLoading;

  const isError =
    !promoCodeMediaItem ||
    getPromoCodeMediaItemError ||
    !userTypeName ||
    getUserTypeError;

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Error error={getPromoCodeMediaItemError || getUserTypeError} />;
  }

  return (
    <div className={styles.container}>
      <PromoCodeMediaDetails promoCodeMediaItem={promoCodeMediaItem} />
      <PromoCodeMediaFreespin
        promoCodeMediaItemFreespin={promoCodeMediaItem.freespin}
      />
      <PromoCodeMediaBonus promoCodeMediaItemBonus={promoCodeMediaItem.bonus} />
    </div>
  );
};
