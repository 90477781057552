import * as SchemaTypes from '../../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { PromoCodeMediaItemData } from './PromoCodeMediaItemData';
import { PageInfoData } from '../../../../../../../../../queries/fragments/generated/PageInfoData';
export type PromoCodeMediaItemsConnectionData = {
  __typename: 'PromoCodeMediaItemConnection';
  edges: Array<{
    __typename: 'PromoCodeMediaItemConnectionEdge';
    cursor: string;
    node: {
      __typename: 'PromoCodeMediaItem';
      createdAt: string;
      id: string;
      referralLink: string;
      tariffName: string;
      token: string;
      type: SchemaTypes.PromoCodeMediaItemType;
      bonus?: {
        __typename: 'PromoCodeBonus';
        cashout?: number | null;
        wager?: number | null;
        amount: {
          __typename: 'MoneyAmount';
          currency: CommonType.Currency;
          value: number;
        };
        minBalance?: {
          __typename: 'MoneyAmount';
          currency: CommonType.Currency;
          value: number;
        } | null;
      } | null;
      freespin?: {
        __typename: 'PromoCodeFreespin';
        count: number;
        currency: CommonType.Currency;
        wager?: number | null;
        minBalance?: {
          __typename: 'MoneyAmount';
          currency: CommonType.Currency;
          value: number;
        } | null;
      } | null;
      owner:
        | { __typename: 'Company'; id: string; name: string }
        | { __typename: 'CompanyPartner'; id: string; username: string }
        | { __typename: 'Partner'; id: string; username: string };
    };
  }>;
  pageInfo: {
    __typename: 'PageInfo';
    endCursor?: string | null;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string;
  };
};

export const PromoCodeMediaItemsConnectionData = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PromoCodeMediaItemsConnectionData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PromoCodeMediaItemConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PromoCodeMediaItemData' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PageInfoData' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
