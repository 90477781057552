import { Currency } from 'types';
import { Language, CurrencyAccount } from 'types/generated/gql';
import { PRIORITY_CURRENCY_CODES } from 'const';
import { getCurrencyDisplayName } from './getCurrencyDisplayName';

type CurrencyDataItem = Currency | CurrencyAccount;
interface GetSortedCurrenciesParams<T extends CurrencyDataItem> {
  currencies?: Array<T>;
  language?: Language;
  selectedCurrency?: Currency;
}

const getCurrencyCode = (currencyItem: CurrencyDataItem): Currency =>
  typeof currencyItem === 'object' ? currencyItem.currency : currencyItem;

const splitCurrenciesByPriority = <T extends CurrencyDataItem>(
  currencies: Array<T>
) =>
  currencies.reduce<{
    priorityCurrencies: Array<T>;
    nonPriorityCurrencies: Array<T>;
  }>(
    (acc, currency) => {
      const currencyCode = getCurrencyCode(currency);

      if (PRIORITY_CURRENCY_CODES.includes(currencyCode)) {
        acc.priorityCurrencies.push(currency);
      } else {
        acc.nonPriorityCurrencies.push(currency);
      }

      return acc;
    },
    {
      priorityCurrencies: [],
      nonPriorityCurrencies: [],
    }
  );

const sortPriorityCurrencies = <T extends CurrencyDataItem>(
  priorityCurrencies: Array<T>
): Array<T> =>
  priorityCurrencies.sort(
    (firstCurrency, secondCurrency) =>
      PRIORITY_CURRENCY_CODES.indexOf(getCurrencyCode(firstCurrency)) -
      PRIORITY_CURRENCY_CODES.indexOf(getCurrencyCode(secondCurrency))
  );

const sortCurrenciesAlphabetically = <T extends CurrencyDataItem>(
  currencies: Array<T>,
  language: Language
): Array<T> =>
  [...currencies].sort((firstCurrency, secondCurrency) => {
    const firstCurrencyName =
      getCurrencyDisplayName({
        currencyCode: getCurrencyCode(firstCurrency),
        language,
      }) || '';

    const secondCurrencyName =
      getCurrencyDisplayName({
        currencyCode: getCurrencyCode(secondCurrency),
        language,
      }) || '';

    return firstCurrencyName.localeCompare(secondCurrencyName);
  });

const moveSelectedCurrencyToTop = <T extends CurrencyDataItem>(
  currencies: Array<T>,
  selectedCurrency?: Currency
): Array<T> => {
  if (!selectedCurrency) return currencies;

  const selectedCurrencyItem = currencies.find(
    (currency) => getCurrencyCode(currency) === selectedCurrency
  );

  if (!selectedCurrencyItem) return currencies;

  return [
    selectedCurrencyItem,
    ...currencies.filter(
      (currency) => getCurrencyCode(currency) !== selectedCurrency
    ),
  ];
};

export const getSortedCurrencies = <T extends CurrencyDataItem>({
  currencies,
  language,
  selectedCurrency,
}: GetSortedCurrenciesParams<T>): Array<T> => {
  if (!currencies || !language) return [];

  const { priorityCurrencies, nonPriorityCurrencies } =
    splitCurrenciesByPriority(currencies);

  const sortedPriorityCurrencies = sortPriorityCurrencies(priorityCurrencies);

  const alphabeticallySortedCurrencies = sortCurrenciesAlphabetically(
    nonPriorityCurrencies,
    language
  );

  const sortedCurrencies = [
    ...sortedPriorityCurrencies,
    ...alphabeticallySortedCurrencies,
  ];

  return moveSelectedCurrencyToTop(sortedCurrencies, selectedCurrency);
};
