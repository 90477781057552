export const getRangeLastReportingPeriod = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const day = today.getDate();

  if (day < 15) {
    return {
      startRange: new Date(year, month - 1, 15),
      endRange: new Date(year, month, 0),
    };
  }

  return {
    startRange: new Date(year, month, 1),
    endRange: new Date(year, month, 14),
  };
};
