import { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';
import styles from './TableColumnCell.module.scss';
import { TableCellAlign } from './types';
import { mapAlignToStyle } from './helpers';

interface TableColumnCellProps {
  isPinned?: boolean;
  left?: number;
  align?: TableCellAlign;
}

export const TableColumnCell: FC<PropsWithChildren<TableColumnCellProps>> = ({
  children,
  isPinned,
  left,
  align = TableCellAlign.Left,
}) => (
  <div
    className={classnames(styles.container, mapAlignToStyle[align], {
      [styles.pinned]: isPinned,
    })}
    style={{
      left: isPinned && left !== undefined ? `${left}px` : undefined,
    }}
    role="columnheader"
  >
    {children}
  </div>
);
