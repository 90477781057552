import { t } from 'i18n';
import { Tab } from 'ui/Tabs';
import { TranslationUserType } from 'types';
import { ReferralLinkMediaItemData } from '../../queries/fragments/generated/ReferralLinkMediaItemData';
import { ReferralLinkMediaDetails } from './components/ReferralLinkMediaDetails';
import { MediaTariff } from './components/MediaTariff';
import { Postbacks } from './components/Postbacks';
import { EditTab, companyEditTypeArray, partnerEditTypeArray } from './const';

export const tabTypeToTab = (
  type: EditTab,
  referralLinkMediaItem: ReferralLinkMediaItemData,
  translationUserType: TranslationUserType
) => {
  const map: Record<EditTab, Tab> = {
    [EditTab.Detail]: {
      title: t(
        `media:${translationUserType}.content.drawer.details.action.tabMenu.aboutMedia`
      ),
      content: (
        <ReferralLinkMediaDetails
          referralLinkMediaItem={referralLinkMediaItem}
        />
      ),
    },
    [EditTab.Tariff]: {
      title: t(
        `media:${translationUserType}.content.drawer.details.action.tabMenu.tariff`
      ),
      content: <MediaTariff tariff={referralLinkMediaItem.tariff} />,
    },
    [EditTab.Postbacks]: {
      title: t(
        `media:${translationUserType}.content.drawer.details.action.tabMenu.postback`
      ),
      content: <Postbacks referralLinkMediaItemId={referralLinkMediaItem.id} />,
    },
  };

  return map[type];
};

export const createTabs = (
  referralLinkMediaItem: ReferralLinkMediaItemData,
  isCompany: boolean
): Array<Tab> => {
  const translationUserType = isCompany
    ? TranslationUserType.Company
    : TranslationUserType.Partner;

  return (isCompany ? companyEditTypeArray : partnerEditTypeArray).map((tab) =>
    tabTypeToTab(tab, referralLinkMediaItem, translationUserType)
  );
};

export const getTabIndex = (tabType: EditTab, isCompany: boolean) =>
  (isCompany ? companyEditTypeArray : partnerEditTypeArray).indexOf(tabType);
