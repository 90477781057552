import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Error } from 'ui/Error';
import { Button, ButtonTheme } from 'ui/Button';
import { Loader } from 'ui/Loader';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { useCreationCardsData } from './hooks/useCreationCardsData';
import { Postback } from './components/Postback';
import { useGetPostbackInfoQuery } from './hooks/UseGetPostbackInfoQuery';
import styles from './Postbacks.module.scss';

interface PostbacksProps {
  referralLinkMediaItemId: string;
}

export const Postbacks: FC<PostbacksProps> = ({ referralLinkMediaItemId }) => {
  const { t } = useTranslation('media');
  const [isCreationInProcess, setIsCreationInProcess] = useState(false);
  const translationUserType = useTranslationUserType();

  const {
    postbackInfo,
    error: getPostbackInfoError,
    loading: getPostbackInfoLoading,
  } = useGetPostbackInfoQuery({
    referralLinkMediaItemId,
  });

  const { creationCards, addMoreCreationCard } = useCreationCardsData({
    referralLinkMediaItemId,
    postbackEvents: postbackInfo?.events,
    isPostbacksExisted: Boolean(postbackInfo?.postbacks?.length),
  });

  const isMaxCards =
    postbackInfo?.postbacks &&
    postbackInfo.postbacks.length + creationCards.length >=
      postbackInfo.events.totalEventsCount;

  const allCardsLength =
    creationCards.length + (postbackInfo?.postbacks?.length || 0);

  const handleAddMoreButtonClick = () => {
    if (isMaxCards) {
      return;
    }

    addMoreCreationCard();
  };

  // if we already have postbacks on the page, we don't need to show Loading
  if (!postbackInfo && getPostbackInfoLoading) {
    return <Loader />;
  }

  if (getPostbackInfoError || !postbackInfo?.postbacks) {
    return <Error error={getPostbackInfoError} />;
  }

  return (
    <>
      <div className={styles.postbacksContainer}>
        {postbackInfo.postbacks.map((postback) => (
          <Postback
            isConnected={postback.isConnected}
            key={postback.id}
            events={[postback.event]}
            id={postback.id}
            referralLinkMediaItemId={referralLinkMediaItemId}
            initialValues={{
              event: postback.event,
              method: postback.method,
              url: postback.url,
            }}
            shouldShowRemoveButton
          />
        ))}
        {creationCards.map((card) => (
          <Postback
            creationState={{
              isCreationInProcess,
              setIsCreationInProcess,
            }}
            shouldShowRemoveButton={allCardsLength > 1}
            key={card.id}
            {...card}
          />
        ))}
      </div>
      {!isMaxCards && (
        <Button
          onClick={handleAddMoreButtonClick}
          isFullWidth
          isLoading={false}
          theme={ButtonTheme.BlueLight}
        >
          {t(
            `${translationUserType}.content.drawer.details.postback.action.button.primary.addAnotherPostback`
          )}
        </Button>
      )}
    </>
  );
};
