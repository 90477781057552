import { ReactComponent as CalendarIcon } from 'assets/icons/line/Calendar.svg';
import { Icon, IconSize } from '../../../Icon';
import styles from './DateRangeTitle.module.scss';

interface DateRangeTitleProps {
  startDate: string | null;
  endDate: string | null;
}

export const DateRangeTitle = ({ startDate, endDate }: DateRangeTitleProps) => (
  <div className={styles.dateTitle}>
    {startDate && (
      <p className={styles.dateText}>
        <Icon
          iconClassName={styles.icon}
          iconComponent={CalendarIcon}
          size={IconSize.Small}
        />
        {startDate}
      </p>
    )}

    {endDate && (
      <p className={styles.dateText}>
        <Icon
          iconClassName={styles.icon}
          iconComponent={CalendarIcon}
          size={IconSize.Small}
        />
        {endDate}
      </p>
    )}
  </div>
);
