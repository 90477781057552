import { FC } from 'react';
import { Falsy } from 'types';
import { useTranslation } from 'react-i18next';
import { KeyValueGrid, KeyValueGridRow } from 'ui/KeyValueGrid';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { PromoCodeFreespin } from 'types/generated/gql';
import { getMoneyAmount } from 'utils/currency/getMoneyAmount';
import { getCurrencyNameWithSymbol } from 'utils/currency/getCurrencyNameWithSymbol';
import { getValueWithFallback } from 'utils/getValueWithFallback';
import { PromoCodeMediaSection } from '../PromoCodeMediaSection';

interface PromoCodeMediaFreespinProps {
  promoCodeMediaItemFreespin?: PromoCodeFreespin | null;
}

export const PromoCodeMediaFreespin: FC<PromoCodeMediaFreespinProps> = ({
  promoCodeMediaItemFreespin,
}) => {
  const { t } = useTranslation('media');
  const translationUserType = useTranslationUserType();

  const getKeyValueGridRows = (
    freespin: PromoCodeFreespin
  ): Array<KeyValueGridRow | Falsy> => [
    {
      title: t(
        `${translationUserType}.content.drawer.details.promoCode.freeSpins.label.quantity`
      ),
      value: `${freespin.count} FS`,
    },
    {
      title: t(
        `${translationUserType}.content.drawer.details.promoCode.freeSpins.label.currency`
      ),
      value: getCurrencyNameWithSymbol(freespin.currency),
    },
    {
      title: t(
        `${translationUserType}.content.drawer.details.promoCode.freeSpins.label.wager`
      ),
      value: getValueWithFallback({ value: freespin.wager }),
    },
    {
      title: t(
        `${translationUserType}.content.drawer.details.promoCode.freeSpins.label.minimumBalance`
      ),
      value: freespin.minBalance ? getMoneyAmount(freespin.minBalance) : '—',
    },
  ];

  if (!promoCodeMediaItemFreespin) return null;

  return (
    <PromoCodeMediaSection
      title={t(
        `${translationUserType}.content.drawer.details.promoCode.freeSpins.label.headline`
      )}
    >
      <KeyValueGrid
        data={getKeyValueGridRows(promoCodeMediaItemFreespin)}
        shouldStackOnMd
      />
    </PromoCodeMediaSection>
  );
};
