import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useModalContext,
  updateConfirmClose,
  closeModal as closeModalAction,
} from 'ui/Modal';
import { Loader } from 'ui/Loader';
import { Error } from 'ui/Error';
import { useConfirmModalContext } from 'ui/ConfirmModal';
import { Button, ButtonSize } from 'ui/Button';
import { FormBlockMarginSize, FormBlockWrapper } from 'ui/formItems';
import { Form, Formik } from 'formik';
import { NextRewardInfo } from 'types/generated/gql';
import { DateFormat, formatDate } from 'utils/formatDate';
import { useCurrencySelectOptions } from 'hooks/useCurrencySelectOptions';
import { Currency } from 'types';
import { useSwitchNextRewardCurrency } from './hooks/useSwitchNextRewardCurrency';
import { FieldName } from './const';
import { NextRewardCurrencySelect } from './components/NextRewardCurrencySelect';
import { NextRewardCurrencyFormValues } from './types';
import styles from './NextRewardCurrencyModal.module.scss';

interface NextRewardCurrencyModalProps {
  nextRewardInfo: NextRewardInfo;
}

export const NextRewardCurrencyModal = ({
  nextRewardInfo,
}: NextRewardCurrencyModalProps) => {
  const { t } = useTranslation('payments');

  const { dispatch } = useModalContext();
  const { showConfirmation } = useConfirmModalContext();
  const [selectedCurrency, setSelectedCurrency] = useState<Currency>();

  const {
    currencySelectOptions,
    error,
    loading: currencyOptionsLoading,
  } = useCurrencySelectOptions(selectedCurrency);

  const initialValues: NextRewardCurrencyFormValues = {
    [FieldName.Currency]: nextRewardInfo.currency,
  };

  const closeModal = useCallback(() => {
    dispatch(closeModalAction());
  }, [dispatch]);

  const handleCompleted = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const { switchNextRewardCurrency, loading: switchCurrencyLoading } =
    useSwitchNextRewardCurrency({
      onCompleted: handleCompleted,
    });

  const periodEnd = formatDate(
    new Date(nextRewardInfo.period.end),
    DateFormat.DayMonth
  );

  const handleDirtyChange = useCallback(
    (dirty: boolean) => {
      dispatch(
        updateConfirmClose({
          confirmClose: dirty ? showConfirmation : null,
        })
      );
    },
    [dispatch, showConfirmation]
  );

  const handleSubmit = (values: NextRewardCurrencyFormValues) => {
    switchNextRewardCurrency(values.currency);
  };

  if (currencyOptionsLoading) {
    return <Loader />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Formik<NextRewardCurrencyFormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ dirty }) => (
        <Form>
          <FormBlockWrapper marginBottomSize={FormBlockMarginSize.Small}>
            <p className={styles.changeNextRewardDeadlineText}>
              {t(
                'partnerAndCompany.content.changeCurrency.content.header.description',
                {
                  monthDayEnd: periodEnd,
                }
              )}
            </p>
          </FormBlockWrapper>
          <FormBlockWrapper marginBottomSize={FormBlockMarginSize.Large}>
            <NextRewardCurrencySelect
              options={currencySelectOptions}
              onDirtyChange={handleDirtyChange}
              disabled={switchCurrencyLoading}
              onChange={setSelectedCurrency}
            />
          </FormBlockWrapper>
          <Button
            type="submit"
            size={ButtonSize.Large}
            disabled={!dirty}
            isLoading={switchCurrencyLoading}
          >
            {t('partnerAndCompany.action.action.button.drawer.changeCurrency')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
