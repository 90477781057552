import { ReactNode } from 'react';
import { Column } from '@tanstack/react-table';
import { calculateLeftOffset } from 'ui/Table/helpers/calculateLeftOffset';
import styles from './TableSummaryRow.module.scss';
import { TableRowCell } from '../TableRowCell';

interface TableSummaryRowProps<TData> {
  row: Array<ReactNode>;
  columns: Array<Column<TData>>;
}

export const TableSummaryRow = <TData,>({
  row,
  columns,
}: TableSummaryRowProps<TData>) => {
  let nonPinnedColumnsWidth = 0;

  return (
    <div className={styles.container} role="row" aria-label="summary-row">
      {row.map((value, index) => {
        const currentColumn = columns[index];
        const { meta, enablePinning } = currentColumn.columnDef;

        const { left, newNonPinnedColumnsWidth } = calculateLeftOffset({
          column: currentColumn,
          nonPinnedColumnsWidth,
        });

        nonPinnedColumnsWidth = newNonPinnedColumnsWidth;

        return (
          <TableRowCell
            isPinned={enablePinning}
            align={meta?.align}
            isSummaryCell
            left={left}
            key={value ? `${value.toString()}${index}` : index}
          >
            {value}
          </TableRowCell>
        );
      })}
    </div>
  );
};
