import { FC } from 'react';
import classNames from 'classnames';
import { IconSize } from 'ui/Icon';
import { EmptyDataBlock } from 'components/EmptyDataBlock';
import styles from './EmptyDataBlockWithContainer.module.scss';

interface EmptyDataBlockWithContainerProps {
  emptyText: string;
  className?: string;
  iconSize?: IconSize.ExtraLarge | IconSize.Large;
}

export const EmptyDataBlockWithContainer: FC<
  EmptyDataBlockWithContainerProps
> = ({ emptyText, className, iconSize = IconSize.ExtraLarge }) => (
  <div className={classNames(styles.container, className)}>
    <EmptyDataBlock emptyText={emptyText} iconSize={iconSize} />
  </div>
);
