export enum FieldName {
  ContactType = 'contactType',
  ContactText = 'contactText',
  CompanyName = 'companyName',
  Website = 'website',
  TrafficType = 'trafficType',
  CurrencySelect = 'currencySelect',
  Email = 'email',
  CreatePassword = 'createPassword',
  ConfirmPassword = 'confirmPassword',
}

export const mapFiledNameToMaxLength = {
  [FieldName.ContactText]: 30,
  [FieldName.CompanyName]: 50,
  [FieldName.Website]: 50,
  [FieldName.TrafficType]: 1000,
  [FieldName.Email]: 50,
};
