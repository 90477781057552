import { useState } from 'react';
import { FormikHelpers } from 'formik';
import { RegistrationValues } from '../types';
import { useRegisterMutationHandlers } from '../../../../../hooks/useRegisterMutationHandlers';
import { useRegisterCompany } from '../queries/generated/RegisterCompany';

export const useRegisterCompanyAndLogIn = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { handleCompleted, handleError } =
    useRegisterMutationHandlers<RegistrationValues>({ isCompany: true });

  const [registerCompany] = useRegisterCompany();

  const registerAndLogin = (
    values: RegistrationValues,
    actions: FormikHelpers<RegistrationValues>
  ) => {
    const {
      contactText,
      createPassword,
      currencySelect,
      email,
      companyName,
      trafficType,
      contactType,
      website,
    } = values;

    if (!contactType || !currencySelect) {
      return undefined;
    }

    setIsLoading(true);

    return registerCompany({
      variables: {
        input: {
          companyName,
          email,
          website: website || null,
          currency: currencySelect,
          password: createPassword,
          trafficType: trafficType || null,
          contact: {
            type: contactType,
            value: contactText,
          },
        },
      },
      onCompleted: async () => {
        await handleCompleted(values);
        setIsLoading(false);
      },
      onError: (err) => {
        handleError(err, actions);
        setIsLoading(false);
      },
    });
  };

  return { registerAndLogin, isLoading };
};
