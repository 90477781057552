import { FC } from 'react';
import classNames from 'classnames';
import { Button, ButtonSize, ButtonTextColor } from 'ui/Button';
import { Tooltip, TooltipTheme } from 'ui/Tooltip';
import { ReactComponent as CopyIcon } from 'assets/icons/line/Copy.svg';
import { useHandleCopyWithTooltipStatus } from 'hooks/useHandleCopyWithTooltipStatus';
import styles from './CopyText.module.scss';

interface CopyTextProps {
  children: string;
  tooltipText: string;
  justifyBetween?: boolean;
  containerClassName?: string;
  truncateText?: boolean;
}

export const CopyText: FC<CopyTextProps> = ({
  children,
  tooltipText,
  justifyBetween = false,
  containerClassName,
  truncateText = false,
}) => {
  const { handleClick, isTooltipVisible } = useHandleCopyWithTooltipStatus({
    text: children,
  });

  return (
    <span className={classNames(styles.container, containerClassName)}>
      <Tooltip
        isOpen={isTooltipVisible}
        tooltipTheme={TooltipTheme.Light}
        tooltipContent={tooltipText}
        tooltipTriggerClassName={styles.tooltipContainer}
        placement="top"
      >
        <Button
          onClick={handleClick}
          linkStyled={{
            inline: false,
            color: ButtonTextColor.BlueGrey,
          }}
          size={ButtonSize.Medium}
          iconConfig={{
            containerClassName: styles.icon,
            iconComponent: CopyIcon,
          }}
          className={classNames(styles.copyTextButton, {
            [styles.withJustifyBetween]: justifyBetween,
            [styles.withTruncateTextContainer]: truncateText,
          })}
          textWrapperClassName={classNames({
            [styles.withTruncateText]: truncateText,
          })}
        >
          {children}{' '}
        </Button>
      </Tooltip>
    </span>
  );
};
