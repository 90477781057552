import { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';
import { TableCellAlign } from './types';
import { mapAlignToStyle } from './helpers';
import styles from './TableRowCell.module.scss';

interface TableRowCellProps {
  isPinned?: boolean;
  align?: TableCellAlign;
  left?: number;
  isSummaryCell?: boolean;
}

export const TableRowCell: FC<PropsWithChildren<TableRowCellProps>> = ({
  children,
  isPinned,
  isSummaryCell = false,
  align = TableCellAlign.Left,
  left,
}) => (
  <div
    role="cell"
    className={classnames(styles.container, mapAlignToStyle[align], {
      [styles.pinned]: isPinned,
      [styles.summaryCell]: isSummaryCell,
    })}
    style={{
      left: isPinned && left !== undefined ? `${left}px` : undefined,
    }}
  >
    {children}
  </div>
);
