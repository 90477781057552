import { t } from 'i18n';

export const getNotUniqueErrorTranslations = (
  isCompany: boolean
): Record<string, string> => ({
  email: isCompany
    ? t('auth:signup.company.input.registration.validation.emailAlreadyExist')
    : t('auth:signup.partner.input.registration.validation.emailAlreadyExist'),
  ...(isCompany
    ? {
        companyName: t(
          'auth:signup.company.input.registration.validation.companyNameAlreadyExists'
        ),
      }
    : {
        username: t(
          'auth:signup.partner.input.registration.validation.userNameAlreadyExist'
        ),
      }),
});
