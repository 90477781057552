import { ApolloError } from '@apollo/client';
import { ErrorCode } from 'const';

export const getGraphQLErrorFieldPath = (
  apolloError: ApolloError | undefined,
  errorCode: ErrorCode
): Record<string, string> | undefined => {
  const error = apolloError?.graphQLErrors.find(
    (err) => err.extensions?.code === errorCode
  );

  return error?.extensions?.path as Record<string, string> | undefined;
};
