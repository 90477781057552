import { useAuth } from 'components/auth';
import { useGetReferralLinkMediaItem } from '../queries/generated/GetReferralLinkMediaItem';

interface UseGetMediaItemQuery {
  referralLinkMediaItemId: string;
}

export const useGetReferralLinkMediaItemQuery = ({
  referralLinkMediaItemId,
}: UseGetMediaItemQuery) => {
  const { user } = useAuth();
  const { data, loading, error } = useGetReferralLinkMediaItem({
    variables: {
      userId: user.id,
      referralLinkMediaItemId,
    },
  });

  const referralLinkMediaItem = data?.user.referralLinkMediaItem;

  return {
    referralLinkMediaItem,
    loading,
    error,
  };
};
